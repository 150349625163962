<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card
        v-if="category !== null"
        class="blog-edit-wrapper"
    >

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col md="6" class="">
            <b-form-group
                label="عنوان"
                label-for="blog-edit-title"
                class=""
            >
              <b-form-input
                  id="blog-edit-seoTitle"
                  v-model="category.seoTitle"
              />
            </b-form-group>
            <span class="" :class="{'text-danger':category.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{category.seoTitle.length}}</small><small class="text-secondary">/</small><small class="text-secondary">60</small></span>
          </b-col>
          <b-col md="6" class="d-flex flex-row justify-content-between align-items-start">
            <b-form-group
                label="آدرس"
                label-for="blog-edit-title"
                class="w-100"
            >
              <b-form-input
                  id="blog-edit-seourl"
                  v-model="category.seourl"
                  v-on:focus="$event.target.select()"
                  ref="myInput"
              />
            </b-form-group>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="h-50 mt-1 ml-2"
                @click="copyText"
            >
              کپی
            </b-button>
          </b-col>
          <!-- Field:  description -->
          <b-col cols="12" class="mt-2">
            <b-form-group
                label="توضیحات"
                label-for="blog-content"
                class="mb-2"
            >
              <b-form-textarea
                  v-model="category.seoDescription"
              ></b-form-textarea>
            </b-form-group>
            <span class="" :class="{'text-danger':category.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{category.seoDescription.length}}</small><small class="text-secondary">/</small><small class="text-secondary">160</small></span>
          </b-col>
          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="updateCategory"
            >
              ذخیره تغییرات
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>

    <b-card v-if="category !== null" class="mt-5">

      <h2>نحوه نمایش در گوگل</h2>

      <div class="col-md-12 my-3">
        <!--- serp Desktop --->
        <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
          <div class="serp-title" >
            <h4 style="color: rgb(26, 13, 171)!important">
              {{category.seoTitle}}
            </h4>
          </div>
          <div class="serp-url">
            <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
              <span style="overflow-wrap: anywhere">{{category.seourl.replaceAll(' ','-')}}/</span>
              <span>
                             {{ 'https://enzamode.ir/blogs' }}
                        <span class="serp-arrow"></span>
                      </span>

            </h5>

          </div>
          <div class="serp-description">
            <h6 style="overflow-wrap: anywhere">
              {{ category.seoDescription }}
            </h6>
          </div>
        </div>
        <!--- serp Mobile --->
        <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
          <div class="serp-title">
            <h6>
              {{category.seoTitle}}
            </h6>
          </div>
          <div class="serp-url d-flex align-items-center flex-wrap" >

            <small >{{category.seourl.replaceAll(' ','-')}}/</small>
            <small>
              {{ 'https://enzamode.ir/blogs' }}
              <small class="serp-arrow"></small>
            </small>
          </div>
          <div class="serp-description">
            <small>
              {{ category.seoDescription }}
            </small>
          </div>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormTextarea,BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import {
  BlogCategoryGetRequest,
  BlogCategoryUpdateBlogCategorySEORequest,
  BlogGetByIdRequest,
  BlogUpdateBlogSEORequest,
  TagGetAllRequest
} from "@/libs/Api/Blog";
import {BlogCategoryGetAllRequest} from "@/libs/Api/Blog";
import {BlogUpdateBlogRequest} from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";

export default {
  name:'BlogCategorySEO',
  title:"سئو دسته بندی بلاگ",
  data() {
    return {
      baseURL:Helper.baseUrl,
      category:null,
      showOverlay:false,
      categoryId:this.$route.params.id,
      blogCategories:null,
      base64ImageSrc:null,
      tags:null,
      blogFile: null,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  async created() {
    await this.getCategory();
  },
  methods:{
    async getCategory(){
      let _this = this;

      let blogCategoryGetRequest = new BlogCategoryGetRequest(_this);
      blogCategoryGetRequest.setId(_this.categoryId);
      await blogCategoryGetRequest.fetch(function (content){
        _this.category = content
      },function (error){
        console.log(error)
      })
    },
    async updateCategory(){
      let _this = this;

      let blogCategoryUpdateBlogCategorySEORequest = new BlogCategoryUpdateBlogCategorySEORequest(_this);
      blogCategoryUpdateBlogCategorySEORequest.setParams(_this.category);
      await blogCategoryUpdateBlogCategorySEORequest.fetch(function (content){
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تغییرات انجام شد.`,
          },
        })
        _this.getCategory();
      },function (error){
        console.log(error)
      })
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand('copy');
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس بلاگ کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  },
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BOverlay,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
}
</script>

<style>
.serp-arrow {
  border-bottom-color: rgb(0, 102, 33);
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-left-color: rgba(0, 0, 0, 0);
  border-left-style: solid;
  border-left-width: 4px;
  border-right-color: rgba(0, 0, 0, 0);
  border-right-style: solid;
  border-right-width: 4px;
  border-top-color: rgb(0, 102, 33);
  border-top-style: solid;
  border-top-width: 5px;
  color: rgb(128, 128, 128);
  cursor: default;
  font-family: arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  height: 0px;
  position: absolute;
  line-height: 27px;
  margin-left: 3px;
  margin-top: 6px;
  text-align: center;
  user-select: none;
  visibility: visible;
  white-space: nowrap;
  width: 0px;
}

.serp-title {
  color: rgb(26, 13, 171)!important;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 21.6px;
  text-align: left;
  text-decoration: none;
  visibility: visible;
  white-space: nowrap;
}

.serp-url {
  color: rgb(0, 102, 33)!important;
  font-family: arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  visibility: visible;
  white-space: nowrap;
}

.serp-description {
  color: rgb(84, 84, 84)!important;
  font-family: arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 18.2px;
  text-align: left;
  visibility: visible;
  word-wrap: break-word;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
